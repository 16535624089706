import IconsIcon from "../../icons/icons.svg";
import React, {useRef, useState} from "react";
import Mentions from "../mentions";
import {useStore} from "../../logic/stores/hooks";
import EmojiPicker from "../emoji-picker";
import {observer} from "mobx-react-lite";
import debounce from "../../lib/utils/debounce";
import debounce_leading from "../../lib/utils/debounce_leading";

function Input() {
  const currentChannelStore = useStore("currentChannelStore")
  const instanceStore = useStore("instanceStore")
  const [message, setMessage] = useState('')
  const typingPeople = currentChannelStore.typingPeople.filter((person: string) => person !== instanceStore.myData?.name)

  const sendMessage = () => {
    currentChannelStore.sendMessage(message)
    setMessage('')
  }

  const onTyping = (value: string) => {
    setMessage(value)

    debounce_leading(() => currentChannelStore.typingHandle(true))()
    debounce(() => currentChannelStore.typingHandle(false), 2000)()
  }

  return (
    <div className={'chat__input--container'}>
      {typingPeople.length > 0 && <div style={{ position: 'absolute', top: -20, fontSize: 10 }}>
        {typingPeople.map((person: string) => (
          <strong>{person} </strong>
        ))}
        <span>pisze...</span>
      </div>}

      {/*<Mentions />*/}
      <EmojiPicker message={message} setMessage={setMessage} />
      <div className={'chat__input'}>
        <input type={'text'} placeholder={'Wpisz wiadomość'} onKeyDown={(event) => {
          if (event.keyCode === 13) {
            sendMessage()
          }
        }} value={message} onChange={(e) => onTyping(e.target.value)}/>
        <button onClick={sendMessage}><strong>Wyślij</strong></button>
      </div>
    </div>
  )
}

export default observer(Input)