import React, {useEffect} from "react"
import "./styles/index.scss"
import Search from "./components/search";
import Header from "./components/header";
import Channels from "./components/channels";
import Footer from "./components/footer";
import Input from "./components/input";
import ChannelHeader from "./components/channel-header";
import Messages from "./components/messages";
import SearchModal from "./components/search-modal";
import NewChannelModal from "./components/new-channel-modal";
import {observer} from "mobx-react-lite";
import {useStore} from "./logic/stores/hooks";


const ChatIts = observer(() => {
  const instanceStore = useStore("instanceStore");
  const currentChannelStore = useStore("currentChannelStore")

  useEffect(() => {
    fetch("https://business.chat.itsharkz.com/api/Chat/Customer/RandomToken")
      .then(res => res.json())
      .then(data => {
        instanceStore.init(data.token)
        instanceStore.connect()
      })
  }, [])

  if (!instanceStore.myData?.name) {
    return null
  }


  return (
      <div className={'chatits'}>
        <div className={'chat__column'}>
          <Search />
          <Header />
          <Channels />
          <Footer />
        </div>
        {currentChannelStore.channel.id && <div className={'chat__main'}>
          <ChannelHeader />
          <Messages />
          <Input />
        </div>}

        <SearchModal />
        <NewChannelModal />
      </div>
  )

})

export default ChatIts