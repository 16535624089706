import React, {useState} from "react";
import IconsIcon from "../../icons/icons.svg";
import Picker from "emoji-picker-react";

function EmojiPicker({message, setMessage}: any) {
  const [pickerVisible, setPickerVisible] = useState(false)

  const enEmojiClick = (e: any, emojiObject: { emoji: string; }) => {
    setMessage(message + emojiObject.emoji)
    setPickerVisible(false)
  }
  return (
    <div className={'chat__button'} style={{ position: 'relative' }}>
      <img src={IconsIcon} alt={'icons'} onClick={() => setPickerVisible(prev => !prev)}/>
      {pickerVisible && <Picker onEmojiClick={enEmojiClick} pickerStyle={{ position: 'absolute', zIndex: '999999', left: '0', bottom: '50px' }} />}
    </div>
  )
}

export default EmojiPicker