import { action, observable, makeAutoObservable } from "mobx";

export class NewChannelStore {
  instanceStore;

  constructor(instanceStore: any) {
    this.instanceStore = instanceStore
    makeAutoObservable(this);
  }

  @observable
  visible: boolean = false;
  foundCustomers: any = [];
  addedMembers: any = [];

  @action
  open() {
    this.visible = true;
  }

  @action
  close() {
    this.visible = false;
  }

  @action
  setListeners() {
    this.instanceStore.instance.connection.on("FindCustomer", (data: any) => {
      console.log('FindCustomer', data)
      this.foundCustomers = data
    });
  }

  @action
  findMembers(value: string) {
    this.instanceStore.instance.findMembers(value)
  }

  @action
  addMember(member: any) {
    if (this.addedMembers.find((m: any) => m.id === member.id)) {
      return;
    }
    this.addedMembers.push(member)
  }

  @action
  removeMember(member: any) {
    this.addedMembers = this.addedMembers.filter((m: any) => m.id !== member.id)
  }

  @action
  addChannel(name: string) {
    const addedMembersIds = () => {
      return this.addedMembers.map((m: any) => m.id)
    }
    this.instanceStore.instance.createChannel(name, addedMembersIds())
  }

  @action
  resetStore() {
    this.foundCustomers = []
    this.addedMembers = []
  }
}