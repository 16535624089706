import { action, makeAutoObservable } from "mobx";
import ChatIts from "../../lib";

export class InstanceStore {
  constructor() {
    makeAutoObservable(this);
  }

  instance: any = undefined;
  channels: any = undefined;
  notReadChannels: any = [];
  channelMembers: any = undefined;

  messages: any = [];
  hasMoreMessages: boolean = false;

  allMembers: any = undefined;
  foundMembers: any = undefined;

  myData: any = undefined;

  @action
  init(token: string) {
    this.instance = new ChatIts(token)
  }

  @action
  connect() {
    if (!this.instance) return
    this.instance.connect().then(() => {
      this.instance.eventListeners({
        getChannels: (data: any) => this.channels = data.items,
        getChannel: (data: any) => {
          this.setMessages(data.messages)
          this.channelMembers = data.customers || []
          // messagesContainer.current && messagesContainer.current.scroll(0, Number.MAX_SAFE_INTEGER)
          this.notReadChannels = this.notReadChannels.filter((channel: any) => channel.channelId !== data.channelId)
        },
        receiveMessage: (data: any) => {
          this.channels.find((channel: any) => channel.id === data.channelId).lastMessage = data
        },
        getMessages: (data: any) => {
          this.setMessages([...this.messages, ...data.messages])
          if (data.messages.length < 1) {
            this.hasMoreMessages = false
          }
        },
        getCustomers: (data: any) => {
          this.allMembers = data
        },
        channelCreated: (data: any) => {
          this.setMessages(data.messages)
          // this.messages = data.messages
          this.channelMembers = data.customers || []
          // messagesContainer.current && messagesContainer.current.scroll(0, Number.MAX_SAFE_INTEGER)
          this.notReadChannels = this.notReadChannels.filter((channel: any) => channel.channelId !== data.channelId)
          this.instance.currentChannel = data.channelId
          this.hasMoreMessages = true
        },
        findCustomer: (data: any) => {
          this.foundMembers = data
        },
        onHello: (data: any) => {
          this.myData = data
        },
        memberOnlineUpdate: (data: any) => {
          this.channelMembers = (() => {
            let newArray = this.channelMembers
            newArray = newArray.map((member: any) => {
              if (member.id === data.customerId) {
                return {...member, online: data.online}
              } else {
                return member
              }
            })

            return newArray
          })
        },
        notReadChannels: (data: any) => {
          if (this.notReadChannels.find((notReadChannel: any) => notReadChannel.channelId === data.channelId) || data.channelId === this.instance.currentChannel) {
            //
          } else {
            this.notReadChannels = [...this.notReadChannels, data]
          }
        }
      })

      this.instance.getChannels()
    })
  }

  @action
  getMessages(channelId: any, take = 30, skip = 0) {
    this.instance.getMessages(channelId, take, skip)
  }

  @action
  setMessages(data: any) {
    this.messages = data
  }
}