import OptionsIcon from "../../icons/options.svg";
import React from "react";
import {observer} from "mobx-react-lite";
import {useStore} from "../../logic/stores/hooks";

function ChannelHeader() {
  const currentChannelStore = useStore("currentChannelStore")

  return (
    <div className={'chat__main--header'}>
      <div/>
      <div className={'chat__header'}>
        <p>{currentChannelStore.channel.name}</p>
        <div className={'chat__header--image'}>
          <img src={`https://eu.ui-avatars.com/api/?name=${currentChannelStore.channel.name}`} alt={'channel'}/>
        </div>
      </div>
      <div className={'chat__options'}>
        <img src={OptionsIcon} alt={'options'}/>
      </div>
    </div>
  )
}

export default observer(ChannelHeader)