import { action, observable, makeAutoObservable } from "mobx";

export class SearchStore {
  constructor() {
    makeAutoObservable(this);
  }

  @observable
  visible: boolean = false;

  @action
  open() {
    this.visible = true;
  }

  @action
  close() {
    this.visible = false;
  }
}