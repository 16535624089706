import Message from "../message";
import React, {useEffect, useRef, useState} from "react";
import {observer} from "mobx-react-lite";
import {useStore} from "../../logic/stores/hooks";
import InfiniteScroll from "react-infinite-scroll-component";

function Messages() {
  const currentChannelStore = useStore("currentChannelStore")
  const messagesWrapper = useRef<HTMLDivElement>(null)
  const [hasMoreMessages, setHasMoreMessages] = useState(true)

  useEffect(() => {
    currentChannelStore.setMessagesWrapper(messagesWrapper)
  }, [messagesWrapper])

  const loadMoreMessages = () => {
    currentChannelStore.getMessages()
  }

  return (
    <div ref={messagesWrapper} id={'scrollableDiv'} style={{
      overflow: 'auto',
      maxHeight: '100%',
      display: 'flex',
      height: 600,
      flexDirection: 'column-reverse'
    }}>
      <InfiniteScroll
        dataLength={currentChannelStore.messagesCount}
        next={loadMoreMessages}
        style={{display: 'flex', flexDirection: 'column-reverse'}}
        inverse={true}
        hasMore={hasMoreMessages}
        loader={null}
        scrollableTarget="scrollableDiv"
        initialScrollY={Number.MAX_SAFE_INTEGER}
        scrollThreshold={1}
        className={'chat__main--messages'}
      >
        {currentChannelStore.messages.map((message: any) => (
          <Message key={message.id} data={message}/>
        ))}
      </InfiniteScroll>
    </div>
  )
}

export default observer(Messages)