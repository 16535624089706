import React from "react";
import {observer} from "mobx-react-lite";
import {useStore} from "../../logic/stores/hooks";


function Message(props: any) {
  const {data} = props
  const instanceStore = useStore("instanceStore")
  const isOutgoing = instanceStore.myData.id !== data.author.id ? 'chat__message' : 'chat__message chat__message--outgoing'

  return (
    <>
      <div className={isOutgoing}>
        <div className={'chat__message--image'}>
          <img src={`https://eu.ui-avatars.com/api/?name=${data.author.name}`} alt={'message sender'}/>
        </div>
        <div className={'chat__message--content'}>

          {data.message.map((item: any, index: number) => {
            return <div className={'chat__message--message'} key={index}>
              <p>{item}</p>
            </div>
          })}
          <div className={'chat__message--date'}>
            <p>{new Date(data.date).toLocaleString()}</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(Message)