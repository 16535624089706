import GearIcon from "../../icons/gear.svg";
import React from "react";
import {useStore} from "../../logic/stores/hooks";
import {observer} from "mobx-react-lite";


function Footer() {
  const instanceStore = useStore("instanceStore");

  return (
    <div className={'chat__column--footer'}>
      <div className={'chat__channel'}>
        <div className={'chat__channel--image'}>
          <img src={`https://eu.ui-avatars.com/api/?name=${instanceStore.myData?.name}`} alt={'avatar'}/>
        </div>
        <div className={'chat__channel--container'}>
          <div>
            <p className={'chat__channel--name'}>{instanceStore.myData?.name}</p>
            {/*<p className={'chat__channel--message'}>Czasami kocham narty</p>*/}
          </div>
          <div>
            <div className={'chat__button'}>
              <img src={GearIcon} alt={'search button'}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(Footer)