import React, {useEffect, useState} from "react";
import PlusIcon from "../../icons/plus_icon.svg";
import CloseIcon from "../../icons/close.svg";
import {useStore} from "../../logic/stores/hooks";
import {observer} from "mobx-react-lite";
import debounce from "../../lib/utils/debounce";

function NewChannelModal() {
  const newChannelStore = useStore('newChannelStore')
  const [channelName, setChannelName] = useState('')

  useEffect(() => {
    if (newChannelStore.instanceStore.instance) {
      newChannelStore.setListeners()
    }
  }, [newChannelStore.instanceStore.instance])

  if (!newChannelStore.visible) {
    return null
  }

  const findMembers = (e: React.ChangeEvent<HTMLInputElement>) => {
    debounce(() => newChannelStore.findMembers(e.target.value), 500)()
  }

  const addMember = (member: any) => {
    newChannelStore.addMember(member)
  }

  const removeMember = (member: any) => {
    newChannelStore.removeMember(member)
  }

  const addChannel = () => {
    newChannelStore.addChannel(channelName)
    newChannelStore.close()
    newChannelStore.resetStore()
  }

  return (
    <div className={'chat__modal'}>
      <div className={'chat__modal--background'} />
      <div className={'chat__modal--content'}>
        <div className={'chat__modal--header'}>
          <div />
          <p>Nowy kanał</p>
          <div className={'chat__modal--close'} onClick={() => newChannelStore.close()}>
            <img src={CloseIcon} alt={'close'}/>
          </div>
        </div>
        <div className={'chat__modal--body'}>
          <div>
            <div className={'chat__search--container'} style={{ marginBottom: 15 }}>
              <div className={'chat__search--icon'}>
                <img src={PlusIcon} alt={'add'}/>
              </div>
              <div className={'chat__search--input'}>
                <input type={'text'} placeholder={'Nazwa kanału'} onChange={(event) => setChannelName(event.target.value)}/>
              </div>
            </div>
            <div className={'chat__search--container'}>
              <div className={'chat__search--icon'}>
                <img src={PlusIcon} alt={'add'}/>
              </div>
              <div className={'chat__search--input'}>
                <input type={'text'} placeholder={'Wyszukaj uczestników'} onChange={findMembers}/>
              </div>
            </div>
            <div style={{ maxHeight: 200, overflow: 'auto', padding: '5px 15px' }}>
              {newChannelStore.foundCustomers.map((member: any) => (
                <div key={member.id} onClick={() => addMember(member)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer' }}>
                  <p style={{ margin: 0, padding: '5px 0' }}>{member.name}</p>
                  <img src={PlusIcon} alt={'add'}/>
                </div>
              ))}
            </div>

            {newChannelStore.addedMembers.length > 0 && <div>
              <p style={{ margin: 0, fontWeight: 'bold', marginTop: 10 }}>Dodani uczestnicy:</p>
              <div style={{ maxHeight: 200, overflow: 'auto', padding: '5px 15px' }}>
                {newChannelStore.addedMembers.map((member: any) => (
                  <div key={member.id} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer' }}>
                    <p style={{ margin: 0, padding: '5px 0' }}>{member.name}</p>
                    <img src={CloseIcon} alt={'add'} onClick={() => removeMember(member)}/>
                  </div>
                ))}
              </div>
            </div>}

            <div style={{ marginTop: 15 }}>
              <button className={'chat__addChannelButton'} onClick={addChannel}>Dodaj</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(NewChannelModal);