import SearchIcon from "../../icons/search_icon.svg";
import CloseIcon from "../../icons/close.svg";
import React from "react";

function Search() {

  return (
    <div className={'chat__column--search'} style={{ display: 'none' }}>
      <div className={'chat__search--container'}>
        <div className={'chat__search--icon'}>
          <img src={SearchIcon} alt={'search'}/>
        </div>
        <div className={'chat__search--input'}>
          <input type={'text'} placeholder={'Czego szukasz?'}/>
        </div>
        <div className={'chat__search--close'}>
          <img src={CloseIcon} alt={'close'}/>
        </div>
      </div>
    </div>
  )
}

export default Search