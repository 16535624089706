import SearchIcon from "../../icons/search_icon.svg";
import React from "react";
import PlusIcon from "../../icons/plus_icon.svg";
import {useStore} from "../../logic/stores/hooks";
import {observer} from "mobx-react-lite";

function Header() {
  const searchStore = useStore("searchStore")
  const newChannelStore = useStore('newChannelStore')
  const instanceStore = useStore("instanceStore");

  return (
    <div className={'chat__column--header'}>
      <div className={'chat__button'} onClick={() => newChannelStore.open()}>
        <img src={PlusIcon} alt={'add channel button'}/>
      </div>
      <div className={'chat__hello'}><p>Cześć, <strong>{instanceStore.myData?.name}</strong></p></div>
      <div className={'chat__button'} onClick={() => searchStore.open()}>
        <img src={SearchIcon} alt={'search button'}/>
      </div>
    </div>
  )
}

export default observer(Header)