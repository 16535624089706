let timer: NodeJS.Timeout | undefined;
function debounce_leading(func: any, timeout = 300){
  return (...args: any) => {
    if (!timer) {
      // @ts-ignore
      func.apply(this, args);
    }
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      timer = undefined;
    }, timeout);
  };
}

export default debounce_leading