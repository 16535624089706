import CloseIcon from "../../icons/close.svg";
import React from "react";
import SearchIcon from "../../icons/search_icon.svg";
import {useStore} from "../../logic/stores/hooks";
import {observer} from "mobx-react-lite";

function SearchModal() {
  const searchStore = useStore("searchStore")

  if (!searchStore.visible) {
    return null;
  }

  return (
    <div className={'chat__modal'}>
      <div className={'chat__modal--background'} />
      <div className={'chat__modal--content'}>
        <div className={'chat__modal--header'}>
          <div />
          <p>Wyszukaj</p>
          <div className={'chat__modal--close'} onClick={() => searchStore.close()}>
            <img src={CloseIcon} alt={'close'}/>
          </div>
        </div>
        <div className={'chat__modal--body'}>
          <div>
            <div className={'chat__search--container'}>
              <div className={'chat__search--icon'}>
                <img src={SearchIcon} alt={'search'}/>
              </div>
              <div className={'chat__search--input'}>
                <input type={'text'} placeholder={'Czego szukasz?'}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(SearchModal);