import React from "react";
import {observer} from "mobx-react-lite";
import {useStore} from "../../logic/stores/hooks";

function Channels() {
  const instanceStore = useStore("instanceStore");
  const currentChannelStore = useStore("currentChannelStore")

  if (!instanceStore.channels) {
    return null
  }

  const setChannelData = (data: any) => {
    currentChannelStore.setChannel(data)
    // instanceStore.getMessages(data.id)
  }

  return (
    <div className={'chat__column--channels'}>
      <p className={'chat__heading'}>Czaty</p>
      <div className={'chat__channels'}>
        {instanceStore.channels.map((channel: any) => (
          <div key={channel.id} className={'chat__channel'} onClick={() => setChannelData(channel)}>
            <div className={'chat__channel--image'}>
              <img src={`https://eu.ui-avatars.com/api/?name=${channel.name}`} alt={'channel'}/>
            </div>
            <div className={'chat__channel--container'}>
              <div>
                <p className={'chat__channel--name'}>{channel.name}</p>
                {channel.lastMessage && <p className={'chat__channel--message'}>{channel.lastMessage.author?.name}: {channel.lastMessage.message}</p>}
              </div>
              {channel.lastMessage?.date && <div>
                <p className={'chat__channel--date'}>{new Date(channel.lastMessage.date).toLocaleTimeString()}</p>
              </div>}
              {/*<div style={{ position: 'absolute', bottom: 15, right: 0, width: 10, height: 10, backgroundColor: '#D3003F', borderRadius: '50%' }} />*/}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default observer(Channels)