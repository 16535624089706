import React from 'react';
import ChatIts from "./chatits";
import { StoresProvider, stores } from "./chatits/logic/stores/stores";

function App() {
  return (
    <StoresProvider value={stores}>
      <ChatIts />
    </StoresProvider>
  );
}

export default App;
