import React from "react";
import { SearchStore } from "./SearchStore";
import {NewChannelStore} from "./NewChannelStore";
import { InstanceStore } from "./InstanceStore";
import {CurrentChannelStore} from "./CurrentChannelStore";

const instanceStore = new InstanceStore()

export const stores = Object.freeze({
  searchStore: new SearchStore(),
  instanceStore: instanceStore,
  newChannelStore: new NewChannelStore(instanceStore),
  currentChannelStore: new CurrentChannelStore(instanceStore)
});

export const storesContext = React.createContext(stores);
export const StoresProvider = storesContext.Provider;