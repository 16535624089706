import {action, observable, makeAutoObservable} from "mobx";
const NewMsgMp3 = require("../../lib/utils/newmsg.mp3")

export class CurrentChannelStore {
  instanceStore;

  constructor(instanceStore: any) {
    this.instanceStore = instanceStore
    makeAutoObservable(this);
    this.messages = this.instanceStore.messages
  }

  @observable
  channel: any = {};
  messages: any = [];
  messagesCount: any = 0;
  messagesWrapper: any = null;
  typingPeople: any = [];

  @action
  setChannel(data: any) {
    if (!this.channel.id) {
      this.instanceStore.instance.connection.on("getChannel", (data: any) => {
        console.log('getChannel', data)
        this.clearMessages()
        this.setMessages(data.messages)
      });
      this.instanceStore.instance.connection.on("receiveMessage", (data: any) => {
        console.log('receiveMessage', data)
        this.receiveMessage(data)
        setTimeout(() => {
          this.messagesWrapper.current.scroll({top: Number.MAX_SAFE_INTEGER, behavior: 'smooth'})
        }, 100)
      });
      this.instanceStore.instance.connection.on("typing", (data: any) => {
        console.log('typing', data)
        this.onTyping(data)
      });
      this.instanceStore.instance.connection.on("getMessages", (data: any) => {
        console.log('getMessages', data)
        this.setMessages(data.messages)
        // setTimeout(() => {
        //   this.messagesWrapper.current.scroll({top: -Number.MAX_SAFE_INTEGER, behavior: 'smooth'})
        // }, 50)
      });
    }
    // this.messages = this.instanceStore.messages;
    this.instanceStore.instance.getChannel(data.id)
    this.channel = data;
  }

  @action
  clearMessages() {
    this.messages = []
  }

  @action
  setMessages(messages: any) {
    // this.messages = messages
    let currentAuthorId = messages[0].author.id
    let messagesToMerge: any = []
    let newMessages: any = this.messages

    const mergeMessages = () => {
      const newMessage = messagesToMerge[messagesToMerge.length - 1]
      let mergedMessages: any = []
      messagesToMerge.forEach((message: any) => {
        mergedMessages.push(message.message)
      })
      newMessage.message = mergedMessages.reverse()
      newMessages.push(newMessage)
      messagesToMerge = []
    }

    messages.forEach((message: any) => {
      if (message.author.id === currentAuthorId) {
        messagesToMerge.push(message)
      } else {
        mergeMessages()
        currentAuthorId = message.author.id
        messagesToMerge.push(message)
      }
    })
    mergeMessages()
    this.messages = newMessages
    this.setMessagesCount()
  }

  @action
  sendMessage(message: any) {
    this.instanceStore.instance.sendMessage(this.channel.id, message, [])
  }

  @action
  receiveMessage(message: any) {
    new Audio(NewMsgMp3).play()
    if (message.channelId === this.channel.id) {
      if (this.messages[0]?.author.id === message.author.id) {
        this.messages[0].message.push(message.message)
      } else {
        const formattedMessage = message
        formattedMessage.message = [message.message]
        this.messages.unshift(formattedMessage)
      }
      this.setMessagesCount()
    }
  }

  @action
  receiveOlderMessages(data: any) {
    // data.forEach((message: any) => {
    //   this.receiveMessage(message)
    // })
    data.forEach((message: any) => {
      message.message = [message.message]
      this.messages.push(message)
    })

    this.setMessagesCount()
  }

  @action
  setMessagesCount() {
    let count = 0
    this.messages.forEach((message: any) => {
      count += message.message.length
    })
    this.messagesCount = count
  }

  @action
  getMessages(take = 30) {
    this.instanceStore.instance.getMessages(
      this.channel.id,
      take,
      this.messagesCount
    )
  }

  @action
  setMessagesWrapper(ref: any) {
    this.messagesWrapper = ref
  }

  @action
  onTyping(data: any) {
    if (data.channelId === this.channel.id) {
      if (data.typing && !this.typingPeople.find((name: string) => name === data.customer.name)) {
        this.typingPeople.push(data.customer.name)
      } else {
        this.typingPeople = this.typingPeople.filter((user: any) => {
          return user !== data.customer.name
        })
      }
    }
  }

  @action
  typingHandle(isTyping: boolean) {
    if (isTyping) {
      this.instanceStore.instance.typing(this.channel.id, true)
    } else {
      this.instanceStore.instance.typing(this.channel.id, false)
    }
  }
}